import React from "react";
import Can from "../../AWS/Can";
import { useLocation } from "react-router";
import { FormattedMessage } from "react-intl";
import { getMenuItemActive } from "../../../utils";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import ability from "../../AWS/ability";

export function Aside() {
  const location = useLocation();

  return (
    <Can I="view" a="moitoiot">
      <li
        className={`menu-item menu-item-submenu ${getMenuItemActive(location, [
          "/moitoiot",
          "/accounting/vatrates",
        ])}`}
        aria-haspopup="true"
        data-menu-toggle="hover"
      >
        <NavLink className={"menu-link menu-toggle "} to="/moitoiot">
          <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Display3.svg")} />
          </span>
          <span className={"menu-text "}>
            <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT" />
          </span>
        </NavLink>
        <div className="menu-submenu">
          <i className="menu-arrow" />
          <ul className="menu-subnav">
            <li className="menu-item menu-item-parent" aria-haspopup="true">
              <span className="menu-link">
                <span className="menu-text">
                  <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT" />
                </span>
              </span>
            </li>

            <Can I="view" a="moitoiot_partnerlist">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/partnerlist"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/moitoiot/partnerlist">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.LIST" />
                  </span>
                </NavLink>
              </li>
            </Can>

            {(!ability.can("view", "moitoiot_partnerlist") || !ability.can("view", "moitoiot_list")) && (
              <Can I="view" a="moitoiot_list">
                <li
                  className={`menu-item ${getMenuItemActive(
                    location,
                    "/moitoiot/list"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className={"menu-link "} to="/moitoiot/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className={"menu-text "}>
                      <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.LIST" />
                    </span>
                  </NavLink>
                </li>
              </Can>
            )}

            <Can I="manage" a="moitoiot_vatrates">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/vatrates"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/moitoiot/vatrates">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.ACCOUNTING.VAT_RATES" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="moitoiot_departments">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/departments"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/moitoiot/departments">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.ACCOUNTING.DEPARTMENTS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="moitoiot_item_group">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/itemgroups"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/moitoiot/itemgroups">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.ITEMGROUPS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="moitoiot_variantgroups">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/variantgroups"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/moitoiot/variantgroups">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.VARIANTGROUPS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="moitoiot_modifiers">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/modifiers"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/moitoiot/modifiers">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.MODIFIERS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="moitoiot_variants">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/variants"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/moitoiot/variants">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.VARIANTS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="moitoiot_pricelist">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/pricelists"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/moitoiot/pricelists">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.PRICELISTS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="moitoiot_item">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/items"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/moitoiot/items">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.ITEMS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="moitoiot_customer">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/customers"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/moitoiot/customers">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.CUSTOMERS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="moitoiot_operators">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/operators"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/moitoiot/operators">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.OPERATORS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="moitoiot_discountsurcharges">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/discountsurcharges"
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className={"menu-link "}
                  to="/moitoiot/discountsurcharges"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.DISCOUNTSURCHARGES" />
                  </span>
                </NavLink>
              </li>
            </Can>
            
            <Can I="manage" a="moitoiot_room">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/rooms"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/moitoiot/rooms">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="RETAIL.ROOM.ROOMS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="moitoiot_locations">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/locations"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/moitoiot/locations">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="RETAIL.LOCATION.LOCATIONS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="upload_moitoiot_version">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/versionsupload"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/moitoiot/versionsupload">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.VERSIONS_UPLOAD" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="moitoiot_assistance">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/assistance"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/moitoiot/assistance">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.ASSISTANCE" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="moitoiot_generalsetup">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/generalsetup"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/moitoiot/generalsetup">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.GENERALSETUP" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="moitoiot_store_data">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/store"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/moitoiot/store">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.STORE_DATA" />
                  </span>
                </NavLink>
              </li>
            </Can>
            
            <Can I="manage" a="moitoiot_logic_ecr">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/logiccashregisters"
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className={"menu-link "}
                  to="/moitoiot/logiccashregisters"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.LOGICELECTRONICCASHREGISTER" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="moitoiot_physical_ecr">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/physicalcashregisters"
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className={"menu-link "}
                  to="/moitoiot/physicalcashregisters"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.PHYSICALELECTRONICCASHREGISTER" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="moitoiot_cashregister_setup">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/cashregistersetup"
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className={"menu-link "}
                  to="/moitoiot/cashregistersetup"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.CASHREGISTERSETUP" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="moitoiot_tallonsetup">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/tallonsetup"
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className={"menu-link "}
                  to="/moitoiot/tallonsetup"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.TALLONSETUP" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="moitoiot_reports">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/reports"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/moitoiot/reports">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.REPORTS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="view" a="moitoiot_collector">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/collector"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/moitoiot/collector">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.COLLECTOR" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="tobacco">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/tobacco"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/moitoiot/tobacco">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.TOBACCO" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="moitoiot_files">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/files"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/moitoiot/files">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.FILES" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="moitoiot_barcode">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/barcodes"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/moitoiot/barcodes">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.BARCODES" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="moitoiot_phases">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/phases"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/moitoiot/phases">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.PHASES" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="moitoiot_payments">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/payments"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/moitoiot/payments">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.PAYMENTS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="moitoiot_takeaway">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/takeaway"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/moitoiot/takeaway">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.TAKEAWAY" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="moitoiot_checkversions">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/moitoiot/checkversions"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/moitoiot/checkversions">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.CHECK_VERSIONS" />
                  </span>
                </NavLink>
              </li>
            </Can>
            
            <li
              className={`menu-item ${getMenuItemActive(
                location,
                "/moitoiot/help"
              )}`}
              aria-haspopup="true"
            >
              <NavLink className={"menu-link "} to="/moitoiot/help">
                <i className="menu-bullet menu-bullet-dot">
                  <span />
                </i>
                <span className={"menu-text "}>
                  <FormattedMessage id="SERVICE.ONLINE_HELP" />
                </span>
              </NavLink>
            </li>


          </ul>
        </div>
      </li>
    </Can>
  );
}
